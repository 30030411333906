<template>
  <section>
    <three-panel
      :topPanelTitle="'배너 관리'"
      :leftPanelTitle="'배너 정보'"
      :rightPanelTitle="'배너 이미지 정보'"
    >
      <template #topPanel>
        <div class="panel-body">
          <table-list-component
            ref="tableListComponent"
            :loading="loading"
            :customSlotInfo="customSlotInfo"
            :items="itemsWithIndex"
            :tableHeight="'238'"
            :headers="headers"
            :searchOpts="srchOpt"
            :nowPerPage="nowPerPage"
            :totalRecords="totalRecords"
            :totalPages="totalPages"
            @getDataFromApi="getMarketBanner"
            @rowClick="rowClick"
          >
            <!-- subHeaderItem -->
            <template #subHeaderItem>
              <input
                type="button"
                value="등록"
                class="bt-line24 cr-orange"
                @click="openPopup('insert')"
              />
            </template>
            <template v-slot:actions_custom="{ item }">
              <td class="text-center" :title="item.actions">
                <v-icon
                  :size="21"
                  color="indigo darken-2"
                  @click="openPopup('delete')"
                >
                  mdi-delete
                </v-icon>
              </td>
            </template>
            <!---- customSlot ---->
            <template v-slot:status_custom="{ item }">
              <td
                class="text-center"
                :style="{ color: getStatusColor(item.status) }"
              >
                {{ item.status }}
              </td>
            </template>
            <template #searchOptions>
              <label class="sort-label">· 배너 구분</label>
              <select
                class="sort-input-select"
                v-model="srchOpt.bannerTypeCode"
              >
                <option value="">전체 </option>
                <option
                  v-for="(item, index) in bnTypeList"
                  :key="index"
                  :value="item.codeId"
                >
                  {{ item.codeName }}
                </option>
              </select>
              <label class="sort-label">· 배너 이름</label>
              <input
                type="text"
                class="sort-input-text"
                maxlength="30"
                dataType="LITERAL"
                @keyup.enter="keyUpEnter"
                v-model="srchOpt.bannerNm"
              />
            </template>
          </table-list-component>
        </div>
      </template>
      <template #leftPanelBtn>
        <div class="panel">
          <p class="panel-top-btns">
            <input
              type="button"
              value="수정"
              class="bt-line24"
              @click="openPopup('update')"
            />
          </p>
        </div>
      </template>
      <template #leftPanel>
        <!-- overlay -->
        <v-overlay absolute="absolute" :value="tabOverlay">
          <v-progress-circular
            indeterminate
            size="50"
            color="deep-orange lighten-2"
          ></v-progress-circular>
        </v-overlay>
        <div class="panel-body" style="height:331px">
          <table class="table-style-default first-bg">
            <colgroup>
              <col width="100px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <td>상태</td>
                <td v-if="clickedItem.useAt === 'Y'" style="color:red">
                  사용
                </td>
                <td v-else-if="clickedItem.useAt === 'N'" style="color:black">
                  보류
                </td>
                <td v-else></td>
              </tr>
              <tr>
                <td>배너 구분</td>
                <td>
                  {{ clickedItem.bannerTypeNm }}
                </td>
              </tr>
              <tr>
                <td>배너 이름</td>
                <td>
                  {{ clickedItem.bannerNm }}
                </td>
              </tr>
              <tr>
                <td>등록일자</td>
                <td>
                  {{ clickedItem.creatDt }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #rightPanelBtn>
        <input
          type="button"
          value="등록"
          class="bt-line24 cr-orange"
          @click="imgOpenPopup('imgInsert')"
        />
      </template>
      <template #rightPanel>
        <!-- overlay -->
        <v-overlay absolute="absolute" :value="tabOverlay">
          <v-progress-circular
            indeterminate
            size="50"
            color="deep-orange lighten-2"
          ></v-progress-circular>
        </v-overlay>
        <div class="panel-body" style="height:330px">
          <v-data-table
            dense
            :fixed-header="true"
            :height="'330'"
            :headers="imgsHeaders"
            :items="clickedItem.bannerImageList"
            :items-per-page="200"
            class="text-center"
            hide-default-footer
            :mobile-breakpoint="0"
          >
            <template v-slot:[`item.bannerImageNm`]="{ item }">
              <td class="text-center">
                {{ item.bannerImageNm }}
              </td>
            </template>
            <template v-slot:[`item.linkUrl`]="{ item }">
              <td class="text-center">
                {{ item.linkUrl }}
              </td>
            </template>
            <template v-slot:[`item.imgDetail`]="{ item }">
              <td class="text-center">
                <input
                  type="button"
                  value="보기"
                  class="bt-line22 "
                  @click="imgOpenPopup('imgDetail', item)"
                />
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <td class="text-center" :title="item.actions">
                <v-icon
                  :size="21"
                  color="indigo darken-2"
                  @click="delBannerImg(item)"
                >
                  mdi-delete
                </v-icon>
              </td>
            </template>
            <!-- no data -->
            <template v-slot:no-data>
              데이터가 없습니다.
            </template>
          </v-data-table>
        </div>
      </template>
    </three-panel>

    <!----------------------- 배너 등록/수정 팝업 ------------------------->
    <banner-handle-pop-up
      :openPopType="openPopType"
      :popShow="popShow"
      :bnTypeList="bnTypeList"
      :nowPerPage="nowPerPage"
      :submitType="submitType"
      :selctedItem="clickedItem"
      @close="result => (popShow = result)"
      @getMarketBanner="getMarketBanner"
      @updateItem="
        item => (
          (this.clickedItem.creatDt = item.creatDt),
          (this.clickedItem.bannerTypeNm = item.bannerTypeNm),
          (this.clickedItem.useAt = item.useAt),
          (this.clickedItem.bannerNm = item.bannerNm),
          (this.clickedItem.bannerSn = item.bannerSn),
          (this.clickedItem.bannerTypeCode = item.bannerTypeCode),
          (this.clickedItem.bannerImageList = item.bannerImageList)
        )
      "
    >
    </banner-handle-pop-up>

    <!----------------------- 배너 보기/수정 팝업 ------------------------->
    <banner-img-pop-up
      :openPopType="openPopType"
      :popShow="popShow"
      :clickedBannerImg="clickedBannerImg"
      :clickedBannerSn="String(clickedItem.bannerSn)"
      @close="result => (popShow = result)"
      @getBannerList="rowClick"
    >
    </banner-img-pop-up>
  </section>
</template>

<script>
import TableListComponent from '@/components/TableListComponent.vue';
import ThreePanel from '@/layouts/ThreePanel.vue';
import CommonMixins from '@/mixins/CommonMixins';
import BannerHandlePopUp from './BannerHandlePopUp';
import BannerImgPopUp from './BannerImgPopUp';
import {
  getBannerlist,
  getDetailBanner,
  deleteBanner,
  deleteBannerImg,
} from '@/api/marketApi';

export default {
  mixins: [CommonMixins],
  components: {
    ThreePanel,
    TableListComponent,
    BannerHandlePopUp,
    BannerImgPopUp,
  },
  data: () => ({
    tabOverlay: false,
    loading: false,
    headers: [
      { text: '상태', value: 'status', width: '15%' },
      { text: '배너 구분', value: 'bannerTypeNm', width: '15%' },
      { text: '배너 이름', value: 'bannerNm', width: '35%', sortable: false },
      { text: '등록일자', value: 'creatDt', width: '15%' },
      { text: '삭제', value: 'actions', width: '15%', sortable: false },
    ],
    customSlotInfo: [
      { name: 'actions', slotName: 'item.actions' },
      { name: 'status', slotName: 'item.status' },
    ],
    srchOpt: {
      bannerNm: '',
      bannerTypeCode: '',
    },
    clickedItem: {
      creatDt: null,
      bannerTypeNm: '',
      useAt: '',
      bannerNm: '',
      bannerSn: '',
      bannerTypeCode: '',
      bannerImageList: [],
    },
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 10,
    bnTypeList: [],
    // popup
    openPopType: '',
    popShow: false,
    submitType: '',
    // 배너이미지 테이블 헤더
    imgsHeaders: [
      {
        text: '배너 이미지 이름',
        value: 'bannerImageNm',
        width: '28%',
        sortable: false,
      },
      { text: '링크', value: 'linkUrl', width: '28%', sortable: false },
      {
        text: '배너 이미지',
        value: 'imgDetail',
        width: '13%',
        sortable: false,
      },
      { text: '삭제', value: 'actions', width: '13%', sortable: false },
    ],
    clickedBannerImg: {},
  }),
  methods: {
    async getMarketBanner(searchParams) {
      this.loading = true;
      const { rows, page, sord, sidx, bannerNm, bannerTypeCode } = searchParams;
      const params = {
        rows,
        page,
        sord,
        sidx,
        bannerNm,
        bannerTypeCode,
      };
      await getBannerlist(params).then(({ data }) => {
        const { rows, totalRecords, totalPages } = data;
        this.tableItems = rows;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
        this.loading = false;
      });
    },
    async rowClick(item) {
      this.tabOverlay = true;
      await getDetailBanner({ bannerSn: item.bannerSn }).then(({ data }) => {
        this.clickedItem.creatDt = data.creatDt;
        this.clickedItem.bannerSn = data.bannerSn;
        this.clickedItem.bannerTypeNm = data.bannerTypeNm;
        this.clickedItem.bannerTypeCode = data.bannerTypeCode;
        this.clickedItem.useAt = data.useAt;
        this.clickedItem.bannerNm = data.bannerNm;
        this.clickedItem.bannerImageList = data.bannerImageList;
        this.tabOverlay = false;
      });
    },
    // 상태 색상
    getStatusColor(sttus) {
      switch (sttus) {
        case '사용':
          return 'red';
        default:
          return 'black';
      }
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    imgOpenPopup(type, item) {
      this.openPopType = type;
      if (type == 'imgInsert' && !this.clickedItem.bannerSn) {
        this.$dialogs.alert('배너 이미지를 등록할 배너를 선택해주세요.');
        return;
      }

      if (type == 'imgInsert' && !item) {
        this.clickedBannerImg = {
          bannerSn: this.clickedItem.bannerSn,
        };
      }
      if (type == 'imgDetail' && item) {
        this.clickedBannerImg = item;
      }
      this.popShow = true;
    },
    async openPopup(type) {
      this.openPopType = type;
      if (type == 'insert') {
        this.submitType = '등록';
      }
      if (type == 'update') {
        //
        if (!this.clickedItem.bannerSn) {
          this.$dialogs.alert('수정할 배너를 선택해주세요.');
          return;
        }
        this.submitType = '수정';
      }
      if (type == 'delete') {
        const result = await this.$dialogs.confirm(
          '<p>배너를 삭제하시겠습니까?</p>',
        );
        if (result.ok) {
          const param = {
            bannerSn: String(this.clickedItem.bannerSn),
          };
          await deleteBanner(param).then(({ data }) => {
            if (data.valid) {
              this.$dialogs
                .alert('삭제 완료 하였습니다.')
                .then(() => {
                  const reqbody = {
                    page: 1,
                    rows: this.nowPerPage,
                    sidx: null,
                    sord: 'asc',
                    bannerNm: '',
                    bannerTypeCode: '',
                  };
                  this.getMarketBanner(reqbody);
                })
                .finally(() => {
                  this.clickedItemsClear();
                });
            }
          });
        }
      }
      this.popShow = true;
    },
    async delBannerImg(item) {
      const result = await this.$dialogs.confirm(
        '<p>배너를 삭제하시겠습니까?</p>',
      );
      if (result.ok) {
        await deleteBannerImg({
          bannerImageSn: String(item.bannerImageSn),
        }).then(({ data }) => {
          if (data.valid) {
            this.$dialogs.alert('삭제 완료 하였습니다.').then(() => {
              const reqBody = {
                bannerSn: this.clickedItem.bannerSn,
              };
              this.rowClick(reqBody);
            });
          }
        });
      }
    },
    getBnrTypeList() {
      // 배너 구분 TYPE
      let bnrList = this.$store.getters['common/getBnrType'];
      for (let i = 0; i < bnrList.length; i++) {
        let dataArr = {
          codeName: '',
          codeId: '',
        };
        dataArr.codeName = bnrList[i].codeName;
        dataArr.codeId = bnrList[i].codeId;
        this.bnTypeList.push(dataArr);
      }
    },
    clickedItemsClear() {
      this.clickedItem.creatDt = null;
      this.clickedItem.bannerTypeNm = '';
      this.clickedItem.useAt = '';
      this.clickedItem.bannerNm = '';
      this.clickedItem.bannerSn = '';
      this.clickedItem.bannerTypeCode = '';
      this.clickedItem.bannerImageList = [];
    },
  },
  created() {
    this.getBnrTypeList();
  },
  computed: {
    bannerTypeCode() {
      return this.$store.state.common.bannerTypeCode;
    },
    itemsWithIndex() {
      return this.tableItems.map((tableItems, index) => ({
        ...tableItems,
        status: this.tableItems[index].useAt === 'Y' ? '사용' : '보류',
        bannerTypeNm: this.tableItems[index].bannerTypeNm,
        createDate: this.tableItems[index].createDate
          ? this.formatDate(
              new Date(this.tableItems[index].createDate),
              'yyyy/MM/dd hh:mm:ss',
            )
          : '',
      }));
    },
  },
};
</script>

<style lang="scss" scoped></style>
