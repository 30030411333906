<template>
  <section>
    <div class="panel-contents">
      <div class="col-default">
        <div class="panel">
          <!---------------------------- 마켓 현황 ---------------------------->
          <h4 class="panel-top-title">대시보드</h4>
          <div class="store-top-preview">
            <div class="store-top-info">
              <p>
                입점 승인 현황
                <router-link to="/market/approve">
                  <button class="bt-line22">
                    보기
                  </button>
                </router-link>
              </p>
              <p>
                <span class="title">승인요청:</span
                ><span v-html="storeStatus.storeReqstCnt" /><small>건</small>
              </p>
              <p>
                <span class="title">승인:</span>
                <span v-html="storeStatus.storeConfmCnt" /><small>건</small>
                <span class="title">보류:</span>
                <span v-html="storeStatus.storeRejectCnt" /><small>건</small>
              </p>
            </div>
            <div class="store-top-info">
              <p>매출 현황</p>
              <p>
                <span class="title">전체:</span>
                <span v-html="storeStatus.salesTotalCnt" /><small>만원</small>
              </p>
              <p>
                <span class="title">오늘:</span>
                <span v-html="storeStatus.salesTodayCnt" /><small>만원</small>
                <span class="title">어제:</span>
                <span v-html="storeStatus.salesYestdCnt" /><small>만원</small>
              </p>
            </div>
            <div class="store-top-info">
              <p>
                컨텐츠 등록 현황
                <router-link to="/market/goods">
                  <button class="bt-line22">
                    보기
                  </button>
                </router-link>
              </p>
              <p>
                <span class="title">전체누적수:</span>
                <span v-html="storeStatus.productTotCnt" /><small>건</small>
              </p>
              <p>
                <span class="title">판매중:</span>
                <span v-html="storeStatus.productInSaleCnt" /><small>건</small>
                <span class="title">대기중:</span>
                <span v-html="storeStatus.productWaitCnt" /><small>건</small>
              </p>
            </div>
            <div class="store-top-info">
              <p>방문/상품조회 현황</p>
              <p>
                <span class="title">오늘 방문수:</span>
                <span v-html="storeStatus.oprtrVisitTdyCnt" /><small>회</small>
              </p>
              <p>
                <span class="title">상품 조회수:</span>
                <span v-html="storeStatus.goodsReadTotCnt" /><small>회</small>
              </p>
            </div>
          </div>
          <!---------------------------- 입점 목록 ---------------------------->
          <h4 class="panel-top-title">판매자 입점 목록</h4>
          <table-list-component
            ref="tableListComponent"
            :tableHeight="'371'"
            :headers="headers"
            :items="tableItems"
            :nowPerPage="nowPerPage"
            :totalRecords="totalRecords"
            :totalPages="totalPages"
            :searchOpts="searchOpts"
            :loading="loading"
            :customSlotInfo="customSlotInfo"
            @getDataFromApi="searchStoreList"
          >
            <!---- 검색 옵션 ---->
            <template #searchOptions>
              <label class="sort-label">· 검색옵션</label>
              <select
                class="sort-input-select"
                style="width: 100px;"
                v-model="searchOpts.searchType"
              >
                <option :value="'all'">전체</option>
                <option :value="'cmpnyNm'">회사명</option>
                <option :value="'oprtrId'">아이디</option>
                <option :value="'oprtrNm'">담당자명</option>
              </select>
              <label for="searchWord" class="sort-label">· 검색어</label>
              <input
                type="text"
                class="sort-input-text"
                v-model="searchOpts.searchWord"
                @keyup.enter="keyUpEnter"
              />
            </template>
            <!---- customSlot ---->
            <template v-slot:confmSttusCode_custom="{ item }">
              <td
                class="text-center"
                :style="{ color: getConfmSttusColor(item.confmSttusCode) }"
              >
                {{ getConfmSttusNm(item.confmSttusCode) }}
              </td>
            </template>
            <template v-slot:salesAmount_custom="{ item }">
              <td class="text-center">
                {{
                  `${new Intl.NumberFormat('ko-KR').format(
                    item.salesAmount,
                  )} 원`
                }}
              </td>
            </template>
            <template v-slot:oprtrIdView_custom="{ item }">
              <td class="text-center">
                <input
                  type="button"
                  value="보기"
                  class="bt-line22"
                  @click="openStoreDetail(item.oprtrId)"
                />
              </td>
            </template>
          </table-list-component>
        </div>
      </div>
    </div>
    <!---------------------------- 업체 상세 정보 ---------------------------->
    <div v-show="popup.show" title="업체 정보">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:500px">
        <div class="pop-head">
          <h2>업체 정보</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <table class="table-style-default table-pop">
            <colgroup>
              <col width="106px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr v-for="(item, key) in popup.selectedItem" :key="key">
                <th>{{ item.name }}</th>
                <td>
                  <a
                    v-if="
                      key == 'bsnmCeregrtFileNm' || key == 'goodsIntrcnFileNm'
                    "
                    class="a_file"
                    @click="fileDownload(key)"
                  >
                    {{ item.value }}
                  </a>
                  <span v-else>
                    {{ item.value }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="pop-btn-group">
            <input
              type="button"
              value="닫기"
              class="bt-line30"
              @click="closePopup"
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TableListComponent from '@/components/TableListComponent.vue';
import { getStoreList, getStoreDetail } from '@/api/marketApi';

export default {
  components: {
    TableListComponent,
  },
  data() {
    return {
      // --- 마켓 현황 --- //
      storeStatus: this.$store.state.market.storeStatus,
      // --- 테이블 --- //
      loading: false,
      headers: [
        { text: '상태', value: 'confmSttusCode', width: '10%' },
        { text: '회사명', value: 'cmpnyNm', width: '10%' },
        { text: '아이디', value: 'oprtrId', width: '13%' },
        { text: '담당자명', value: 'oprtrNm', width: '13%' },
        { text: '판매건수', value: 'sellCount', width: '7%' },
        { text: '판매매출', value: 'salesAmount', width: '10%' },
        { text: '가입일시', value: 'selerRegistDt', width: '15%' },
        { text: '입점일시', value: 'confmDt', width: '15%' },
        {
          text: '업체정보',
          sortable: false,
          value: 'oprtrIdView',
          width: '7%',
        },
      ],
      // --- custom Slot --- //
      customSlotInfo: [
        { name: 'confmSttusCode', slotName: 'item.confmSttusCode' },
        { name: 'salesAmount', slotName: 'item.salesAmount' },
        { name: 'oprtrIdView', slotName: 'item.oprtrIdView' },
      ],
      // 검색 정보
      searchOpts: {
        searchType: 'all',
        searchWord: '',
        pageNo: -1,
      },
      // row
      totalRecords: 0,
      totalPages: 0,
      tableItems: [],
      nowPerPage: 30,
      // --- 팝업 (상세정보) --- //
      popup: {
        show: false,
        selectedItem: {
          oprtrId: {
            name: '일련번호',
            value: '',
          },
          cmpnyNm: {
            name: '회사명',
            value: '',
          },
          chargerTelno: {
            name: '회사전화',
            value: '',
          },
          oprtrNm: {
            name: '담당자명',
            value: '',
          },
          mbtlnum: {
            name: '연락처',
            value: '',
          },
          email: {
            name: '이메일',
            value: '',
          },
          cmpnyIntrcnCn: {
            name: '회사소개',
            value: '',
          },
          bsnmCeregrtFileNm: {
            name: '사업자등록증',
            value: '',
          },
          goodsIntrcnCn: {
            name: '상품소개',
            value: '',
          },
          goodsIntrcnFileNm: {
            name: '상품소개서',
            value: '',
          },
        },
      },
    };
  },
  methods: {
    // 입점 목록
    async searchStoreList(searchParams) {
      this.loading = true;
      await getStoreList(searchParams).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.tableItems = rows;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
        this.loading = false;
      });
    },
    // 업체 상세 정보 팝업 open
    async openStoreDetail(oprtrId) {
      await getStoreDetail(oprtrId).then(({ data }) => {
        Object.keys(this.popup.selectedItem).forEach(key => {
          this.popup.selectedItem[key].value = data[key];
        });
        this.popup.show = true;
      });
    },
    // 업체 파일 다운로드
    fileDownload(type) {
      // 다운로드 유형 (사업자등록증/상품소개서)
      let downloadPath =
        type == 'bsnmCeregrtFileNm'
          ? 'bsnmCeregrtFileView'
          : 'goodsIntrcnFileView';
      // 다운로드
      let oprtrId = this.popup.selectedItem.oprtrId.value;
      window.location.href = `${process.env.VUE_APP_URL_MAS_API}/market/store/${downloadPath}/${oprtrId}?token=${this.$store.state.auth.token}`;
    },
    // popup close
    closePopup() {
      this.popup.show = false;
      Object.keys(this.popup.selectedItem).forEach(key => {
        this.popup.selectedItem[key].value = '';
      });
    },
    // 상태 색상
    getConfmSttusColor(sttus) {
      switch (sttus) {
        case '1':
          return 'blue';
        case '2':
          return 'grey';
        default:
          return 'red';
      }
    },
    // 상태명
    getConfmSttusNm(sttus) {
      switch (sttus) {
        case '1':
          return '승인';
        case '2':
          return '보류';
        default:
          return '신규';
      }
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
  },
};
</script>

<style scoped>
.store-top-preview {
  height: 180px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  margin-bottom: 20px;
}
.store-top-preview div {
  padding: 0px 20px;
  margin: 10px 0px;
}
.store-top-preview div:nth-child(1) {
  width: 25%;
  border-right: 1px solid #ddd;
}
.store-top-preview div:nth-child(1) button {
  position: relative;
  top: -2px;
  margin-left: 20px;
}
.store-top-preview div:nth-child(2) {
  width: 25%;
  border-right: 1px solid #ddd;
}

.store-top-preview div:nth-child(2) button {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

.store-top-preview div:nth-child(3) {
  width: 25%;
  border-right: 1px solid #ddd;
}
.store-top-preview div:nth-child(3) button {
  position: relative;
  top: -2px;
  margin-left: 10px;
}
.store-top-preview div:nth-child(4) {
  width: 25%;
}

.store-top-info {
  text-align: center;
}

.store-top-info .title {
  font-size: 13px !important;
  font-weight: 700;
  vertical-align: middle;
  margin-right: 20px;
}

.store-top-info p:nth-child(1) {
  line-height: 50px;
  font-size: 15px;
  font-weight: bold;
}

.store-top-info p:nth-child(2) {
  font-size: 30px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}
.store-top-info p:nth-child(2) > small {
  font-size: 20px;
  margin-left: 4px;
  opacity: 0.4;
}

.store-top-info p:nth-child(3) {
  font-size: 30px;
  font-weight: bold;
}

.store-top-info p:nth-child(3) > small {
  font-size: 20px;
  margin-left: 4px;
  opacity: 0.4;
}

.store-top-info p:nth-child(3) > span:nth-child(4) {
  margin-left: 10px;
}

.store-top-info-detail p:nth-child(1) {
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

.store-top-info-detail p > input {
  float: right;
  margin-top: 10px;
}
.store-top-info-detail p {
  font-size: 13px;
  line-height: 25px;
  text-align: right;
}
.store-top-info-detail p > label {
  font-weight: 800;
  float: left;
}
.store-top-info-detail p > span {
  font-weight: 800;
}
.store-top-info-detail p > span > small {
  margin-left: 4px;
  opacity: 0.6;
}
.file-name-div {
  display: inline-block;
  width: 250px;
  overflow: auto;
}
</style>
